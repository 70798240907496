<template>
	<PageLayout :title="$t('confirm-phone.title')">
		<template #subtitle>
			<PageSubtitle>{{ $t('confirm-phone.verification-subtitle', { number: getUser.mobilenumber }) }}</PageSubtitle>
			<PageSubtitle>
				{{ $t('confirm-phone.wrong-phone-number') }}
				<router-link to="/confirm-phone" replace>
					{{ $t('confirm-phone.change-here') }}
				</router-link>
			</PageSubtitle>
		</template>
		<div class="small-width">
			<MaterialVerificationCodeInput auto-focus @change="handleVerificationCodeChange" />
			<CLabel faded small>
				{{ $t('confirm-phone.did-not-receive-code') }}
				<router-link to="/confirm-phone" replace>
					{{ $t('confirm-phone.resend') }}
				</router-link>
			</CLabel>
			<CButton primary :disabled="!verificationCodeComplete" :loading="loading" @click="handleConfirmCode">
				{{ $t('actions.confirm') }}
			</CButton>

			<FormError v-if="networkError" error="network-error" />
			<FormError v-if="error" :error="error" />
		</div>
	</PageLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import * as Sentry from '@sentry/vue';
import PageLayout from '@/components/page-layout/PageLayout';
import CButton from '@/shared/cbutton/CButton';
import MaterialVerificationCodeInput from '@/shared/material-verification-code-input/MaterialVerificationCodeInput';
import CLabel from '@/shared/clabel/CLabel';
import PageSubtitle from '@/components/page-subtitle/PageSubtitle';
import apiClient from '@/api';
import FormError from '@/shared/forms/FormError';

export default {
	name: 'VerifySMSCode',
	components: {
		PageSubtitle,
		FormError,
		CLabel,
		CButton,
		MaterialVerificationCodeInput,
		PageLayout,
	},
	computed: {
		...mapGetters(['getUser']),
		verificationCodeComplete() {
			return this.verificationCode.length === 6;
		},
	},
	data() {
		return {
			verificationCode: '',
			loading: false,
			success: false,
			error: null,
			networkError: false,
		};
	},
	methods: {
		handleVerificationCodeChange(value) {
			this.verificationCode = value;
		},
		async handleConfirmCode() {
			this.loading = true;
			this.error = null;
			this.networkError = false;

			try {
				await apiClient.verifyMobileConfirmationToken(this.verificationCode);
				await this.$router.push('/');
			} catch (e) {
				const { response } = e;
				if (!response) {
					Sentry.captureException(e);
					this.networkError = true;
				} else {
					this.error = response.data.error;
				}
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
